const HeroBanner = props => {

  const {
    bannerBody,
    bannerTitle,
    bannerEyebrow,
    bannerButtons
  } = props

  return (
    <div className="home-hero-banner">
      <div className="hero-banner-inner">
        <h4 className='link'>{bannerEyebrow}</h4>
        <h2 className='heading2 intro__heading'>{bannerTitle}</h2>
      </div>
      <div className="home-hero-buttons">
        {bannerButtons.map(btn=>
          <a className="link" href={btn.button_link_copy.url} target={btn.button_link_copy.target} rel="noopener noreferrer">{btn.button_link_copy.title}</a>
        )}
        </div>
      <p>{bannerBody}</p>
    </div>
  )
}

export default HeroBanner

import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Head from 'next/head'
import HeroBanner from './HeroBanner'

const HeroFullbleed = props => {

  const router = useRouter()
  
  const isHome = router.asPath === ('/') || router.asPath === ('/#skip')

  const {
    title = 'HTML Title<br>Content',
    body = 'Body content.',
    bottomLeft = false,
    heroTitle,
    heroBody,
    bgImage = 'https://placekitten.com/1920/1080',
    minHeight = '400px',
    left = false,
    disableArrow = false,
    hideDate,
    bannerVisible = false,
    bannerBody = 'Body',
    bannerTitle = 'Title',
    bannerEyebrow = 'Subtitle',
    bannerButtons= "",
    carouselSet = false,
    carouselArray = "",
  } = props

  //make sure to preload images below as well
  const [imgSlide, setImgSlide] = useState(0)
  let imgArray = carouselArray 
  let imgUp = imgArray[imgSlide]
  setTimeout(() => {
    if (imgSlide === (imgArray.length-1)) {
      setImgSlide(0)
    } else {
      setImgSlide(imgSlide + 1)
    }
  }, 5000);


  return (
    <>
    {carouselSet &&   
    <Head>
      {imgArray.map(img => 
        <link rel="preload" href={img} as="image" key={img}/>
      )}
    </Head>}
      <div
        className={`${
          props.centered ? 'centered mobileOnDesktop' : ''
        } animate-intro container-fluid intro__fullbleed`}
        style = {{backgroundImage: "url(" + (carouselSet ? imgUp : bgImage) + ")", transition: "background-image ease 1s"}}
      >
        <div
          className={`row ${bottomLeft ? 'bottom-left' : 'align-items-center'}`}
        >
          <div className='col-12 intro__mobileimg d-lg-none' style = {{backgroundImage: "url(" + (carouselSet ? imgUp : bgImage) + ")", transition: "background-image ease 1s"}}></div>
          {/*.col-12 .intro__mobileimg*/}

          <div
            className={`slide-left-intro intro__callout-text col-10 col-lg-6 offset-1 ${
              left
                ? 'offset-lg-0 flex-column'
                : props.centered
                ? ''
                : bottomLeft
                ? 'offset-lg-0 bottom-left'
                : 'offset-lg-6 flex-column'
            } d-flex  justify-content-center `}
            style={{padding: 0, justifyContent: "space-between !important", minHeight: "auto"}}
          >
            {bannerVisible && 

              <HeroBanner 
                bannerEyebrow={bannerEyebrow}
                bannerTitle={bannerTitle}
                bannerBody={bannerBody}
                bannerButtons={bannerButtons}
              />
            }
            <div className='bluebox' style={{padding: "7%"}}>
              <h1
                className='heading2 intro__heading'
                style={{fontSize: "35px"}}
                dangerouslySetInnerHTML={{
                  __html: heroTitle ? heroTitle : title
                }}
              />
              {!hideDate ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: heroBody ? heroBody : body
                  }}
                />
              ) : (
                ''
              )}

              {!disableArrow && (
                <a
                  role='button'
                  aria-label='Scroll to next section'
                  className='intro__link link--scroll'
                  href='#skip'
                  style={{marginTop: "15px"}}
                >
                  <img
                    alt='Down Arrow'
                    className='link__icon'
                    src='/public-next/images/down-arrow.png'
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
      @media (prefers-reduced-motion) {
        .intro__fullbleed {
          transition: background-image ease 0.3s !important;
        }
      }
      @media (max-width: 991px) {
        .slide-left-intro {
          margin-top: -100px;
        }
      }
        .intro__fullbleed,
        .intro__fullbleed .intro__mobileimg {
          background-image: url(${bgImage});
        }

        .intro__fullbleed {
          min-height: ${minHeight};
        }
        @media (max-width: 1390px) {
          .intro__fullbleed {
            height: 90vh !important;
          }
        }
        @media (max-width: 991px) {
          .intro__fullbleed {
            height: auto !important;
            min-height: 400px;
          }
        }
        @media (max-width: 991px) {
          .bluebox {
            margin: 0 auto;
          }
        }
       
      `}</style>
    </>
  )
}

export const BGIMAGE = 'url(https://via.placeholder.com/960x790.png)'
export const MINHEIGHT = '100px'
export default HeroFullbleed

import Link from "next/link";
import React, { useState, useEffect, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import NavigationData from "../../data/nav.remotejson.js";

function useMenuState() {
  const [active, setActive] = useState(false);
  return [active, () => setActive(!active)];
}

function useSubnavState() {
  const [activeSubNav, setActiveNav] = useState(null);
  return [
    activeSubNav,
    setActiveNav,
    (item) => setActiveNav(activeSubNav !== item.ID ? item.ID : false),
  ];
}

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addListener(updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  }, []);

  return targetReached;
};

const MainNavigation = React.memo((props) => {
  const { visible, position } = props;
  const [active, toggleActive] = useMenuState();
  const [activeSubNav, setActiveNav, toggleSubNav] = useSubnavState();
  const isBreakpoint = useMediaQuery(1150);

  useEffect(() => {
    document.body.onclick = (evt) => {
      if (!evt.target.classList.contains("nav-link")) {
        setActiveNav(null);
      }
    };
    [];
  });

  const renderNav = () => (
    <ul id="main-menu" className="navbar-nav ml-auto">
      {NavigationData.items.map(renderNavItem)}
    </ul>
  );

  const renderNavItem = (item) => {
    const isSubNavActive = activeSubNav === item.ID;
    const isDropdown = item.url === "#";
    const isMainNav = (item.slug || (item.url && !item.slug)) && !isDropdown;

    return isMainNav ? (
      <li
        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-182 nav-item"
        style={{ position: "relative" }}
        key={item.ID}
      >
        <a
          href={item.slug ? "/" + item.slug : item.url}
          target={item.target}
          className="nav-link"
        >
          {item.title}
        </a>
      </li>
    ) : (
      <li
        className={
          "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-16 nav-item" +
          (isSubNavActive ? " show" : "")
        }
        style={{ position: "relative" }}
        key={item.ID}
      >
        <a
          aria-haspopup="true"
          aria-expanded={isSubNavActive}
          className="dropdown-toggle nav-link"
          tabIndex="0"
          style={{ userSelect: "none" }}
          onClick={() => toggleSubNav(item)}
          onKeyPress={(ENTER, SPACE) => toggleSubNav(item)}
        >
          {item.title}
        </a>
        {renderSubNav(item.ID, item.child_items)}
      </li>
    );
  };

  const renderSubNav = (id, childItems) =>
    childItems && (
      <ul
        style={{ marginTop: 0 }}
        className={"dropdown-menu" + (id === activeSubNav ? " show" : "")}
        aria-labelledby="menubutton"
        role="menu"
        onBlur={toggleActive}
      >
        {childItems.map((item) => (
          <li
            key={item.ID}
            role="menuitem"
            className={
              "menu-item menu-item-type-post_type menu-item-object-page menu-item-209 nav-item " +
              (item.classes && item.classes)
            }
          >
            {item.slug !== "lift-your-mask" ? (
              <a
                href={item.slug ? "/" + item.slug : item.url}
                target={item.target}
                className="dropdown-item"
              >
                {item.title}
              </a>
            ) : (
              <a
                href={"https://thequellfoundation.org/liftthemask/"}
                role="menuitem"
                className="dropdown-item"
              >
                {item.title}
              </a>
            )}
            {item.child_items &&
              item.child_items.map((child2Item) => (
                <a
                  href={
                    child2Item.slug ? "/" + child2Item.slug : child2Item.url
                  }
                  target={child2Item.target}
                  className="dropdown-item label-sub-item"
                >
                  {child2Item.title}
                </a>
              ))}
          </li>
        ))}
      </ul>
    );

  return (
    <div
      id="wrapper-navbar"
      className={``}
      itemScope
      itemType="http://schema.org/website"
    >
      <a href="#content" className="skip-link sr-only sr-only-focusable">
        Skip to Content
      </a>
      <Navbar
        className={`navbar navbar-expand-custom navbar-dark nav-down ${
          props.hidden == true ? "hidden" : ""
        }`}
        style={{ top: position == "top" ? "0px" : "-75px" }}
        aria-label="Main"
        expand="lg"
      >
        <Link href="/" passHref>
          <Navbar.Brand
            href="/"
            bg="light"
            className="navbrand-brand"
            rel="home"
            itemProp="url"
            aria-label="Home Page"
          >
            <img
              style={{ height: "32px", minWidth: "150px" }}
              src="/public-next/images/the-quell-foundation-horiz.svg"
              alt="The Quell Foundation"
            />
          </Navbar.Brand>
        </Link>

        {isBreakpoint ? (
          <button
            data-toggle="collapse"
            onClick={toggleActive}
            data-target="#navbarNavDropdown"
            className={`hamburger hamburger--squeeze d-lg-none float-right navbar-toggler menu-button ${
              active ? "is-active" : ""
            }`}
            id="menubutton"
            aria-label="Menu"
            aria-haspopup="true"
            aria-controls="mobilemenu"
            aria-expanded={`${active ? "true" : "false"}`}
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        ) : (
          ""
        )}

        <div
          id="navbarNavDropdown"
          className={`navbar-collapse collapse${active ? "show" : ""}`}
        >
          {renderNav()}
        </div>
      </Navbar>
      <style jsx>{`
        .dropdown-toggle:focus {
        }
      `}</style>
    </div>
  );
});

export default MainNavigation;
